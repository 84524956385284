/**
 * Enum defining the different types of possible locations
 */
export enum LocationType {
  Company,
  Gym,
  Hospital,
  Slums,
  Special, // This location has special options/activities (e.g. Bladeburner, Re-sleeving)
  StockMarket,
  TechVendor,
  TravelAgency,
  University,
  Casino,
}
