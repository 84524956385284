import { IPredefinedTheme } from "../../Themes";
import img1 from "./screenshot.png";

export const Theme: IPredefinedTheme = {
  name: "Dark Blue",
  description: "Very dark with a blue/purplelly primary",
  credit: "Saynt_Garmo",
  reference: "https://discord.com/channels/415207508303544321/921991895230611466/923084732718264340",
  screenshot: img1,
  colors: {
    primarylight: "#023DDE",
    primary: "#4A41C8",
    primarydark: "#005299",
    successlight: "#00FF00",
    success: "#D1DAD1",
    successdark: "#BFCABF",
    errorlight: "#f00",
    error: "#c00",
    errordark: "#900",
    secondarylight: "#AAA",
    secondary: "#888",
    secondarydark: "#666",
    warninglight: "#ff0",
    warning: "#cc0",
    warningdark: "#990",
    infolight: "#69f",
    info: "#36c",
    infodark: "#039",
    welllight: "#444",
    well: "#040505",
    white: "#fff",
    black: "#000000",
    hp: "#dd3434",
    money: "#ffd700",
    hack: "#adff2f",
    combat: "#faffdf",
    cha: "#a671d1",
    int: "#6495ed",
    rep: "#faffdf",
    disabled: "#66cfbc",
    backgroundprimary: "#091419",
    backgroundsecondary: "#000000",
    button: "#000000",
  },
};
