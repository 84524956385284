/**
 * All possible Cities in the game. Names only, not actual "City" object
 * Implemented as an enum for typing purposes
 */
export enum CityName {
  Aevum = "Aevum",
  Chongqing = "Chongqing",
  Ishima = "Ishima",
  NewTokyo = "New Tokyo",
  Sector12 = "Sector-12",
  Volhaven = "Volhaven",
}
