import { IPredefinedTheme } from "../../Themes";
import img1 from "./screenshot.png";

export const Theme: IPredefinedTheme = {
  name: "One Dark",
  description: "Dark with a greenish tint",
  credit: "Dexalt142",
  reference: "https://discord.com/channels/415207508303544321/921991895230611466/924650660694208512",
  screenshot: img1,
  colors: {
    primarylight: "#98C379",
    primary: "#98C379",
    primarydark: "#98C379",
    successlight: "#98C379",
    success: "#98C379",
    successdark: "#98C379",
    errorlight: "#E06C75",
    error: "#BE5046",
    errordark: "#BE5046",
    secondarylight: "#AAA",
    secondary: "#888",
    secondarydark: "#666",
    warninglight: "#E5C07B",
    warning: "#E5C07B",
    warningdark: "#D19A66",
    infolight: "#61AFEF",
    info: "#61AFEF",
    infodark: "#61AFEF",
    welllight: "#4B5263",
    well: "#282C34",
    white: "#ABB2BF",
    black: "#282C34",
    hp: "#E06C75",
    money: "#E5C07B",
    hack: "#98C379",
    combat: "#ABB2BF",
    cha: "#C678DD",
    int: "#61AFEF",
    rep: "#ABB2BF",
    disabled: "#56B6C2",
    backgroundprimary: "#282C34",
    backgroundsecondary: "#21252B",
    button: "#4B5263",
  },
};
