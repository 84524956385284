// Defs for job titles, stored in arrays and categorized by job "type"

export const SoftwareCompanyPositions: string[] = [
  "Software Engineering Intern",
  "Junior Software Engineer",
  "Senior Software Engineer",
  "Lead Software Developer",
  "Head of Software",
  "Head of Engineering",
  "Vice President of Technology",
  "Chief Technology Officer",
];

export const ITCompanyPositions: string[] = ["IT Intern", "IT Analyst", "IT Manager", "Systems Administrator"];

export const SecurityEngineerCompanyPositions: string[] = ["Security Engineer"];

export const NetworkEngineerCompanyPositions: string[] = ["Network Engineer", "Network Administrator"];

export const BusinessCompanyPositions: string[] = [
  "Business Intern",
  "Business Analyst",
  "Business Manager",
  "Operations Manager",
  "Chief Financial Officer",
  "Chief Executive Officer",
];

export const SecurityCompanyPositions: string[] = [
  "Police Officer",
  "Police Chief",
  "Security Guard",
  "Security Officer",
  "Security Supervisor",
  "Head of Security",
];

export const AgentCompanyPositions: string[] = ["Field Agent", "Secret Agent", "Special Operative"];

export const MiscCompanyPositions: string[] = ["Waiter", "Employee"];

export const SoftwareConsultantCompanyPositions: string[] = ["Software Consultant", "Senior Software Consultant"];

export const BusinessConsultantCompanyPositions: string[] = ["Business Consultant", "Senior Business Consultant"];

export const PartTimeCompanyPositions: string[] = ["Part-time Waiter", "Part-time Employee"];
