import { IPredefinedTheme } from "../../Themes";
import img1 from "./screenshot.png";

export const Theme: IPredefinedTheme = {
  name: "Default-lite",
  description: "Less eye-straining default theme",
  credit: "NmuGmu",
  reference: "https://discord.com/channels/415207508303544321/921991895230611466/925263801564151888",
  screenshot: img1,
  colors: {
    primarylight: "#28CF28",
    primary: "#21A821",
    primarydark: "#177317",
    successlight: "#1CFF1C",
    success: "#16CA16",
    successdark: "#0D910D",
    errorlight: "#FF3B3B",
    error: "#C32D2D",
    errordark: "#8E2121",
    secondarylight: "#B3B3B3",
    secondary: "#838383",
    secondarydark: "#676767",
    warninglight: "#FFFF3A",
    warning: "#C3C32A",
    warningdark: "#8C8C1E",
    infolight: "#64CBFF",
    info: "#3399CC",
    infodark: "#246D91",
    welllight: "#404040",
    well: "#1C1C1C",
    white: "#C3C3C3",
    black: "#0A0B0B",
    hp: "#C62E2E",
    money: "#D6BB27",
    hack: "#ADFF2F",
    combat: "#E8EDCD",
    cha: "#8B5FAF",
    int: "#537CC8",
    rep: "#E8EDCD",
    disabled: "#5AB5A5",
    backgroundprimary: "#0C0D0E",
    backgroundsecondary: "#121415",
    button: "#252829",
  },
};
