/*
The game cannot block every possible exploits. Specially since one of them is
that you can just edit your save file and that's impragmatic to prevent.

So instead we have source file minus 1. It is not obtained by destroying a
BitNode but instead it is awarded when the player finds innovative ways to break
the game, this serves 2 purpose, [one] the developpers don't have to spend time
trying to implement anti-cheat measures and [two] finding ways to break a
hacking game is very much in the spirit of the game.
Source-File minus 1 is extremely weak because it can be fully level up quickly.
*/

export enum Exploit {
  Bypass = "Bypass",
  PrototypeTampering = "PrototypeTampering",
  Unclickable = "Unclickable",
  UndocumentedFunctionCall = "UndocumentedFunctionCall",
  TimeCompression = "TimeCompression",
  RealityAlteration = "RealityAlteration",
  N00dles = "N00dles",
  YoureNotMeantToAccessThis = "YoureNotMeantToAccessThis",
  TrueRecursion = "TrueRecursion",
  INeedARainbow = "INeedARainbow",
  // To the players reading this. Yes you're supposed to add EditSaveFile by
  // editing your save file, yes you could add them all, no we don't care
  // that's not the point.
  EditSaveFile = "EditSaveFile",
}

const names: {
  [key: string]: string;
} = {
  Bypass: "by circumventing the ram cost of document.",
  EditSaveFile: "by editing your save file.",
  PrototypeTampering: "by tampering with Numbers prototype.",
  TimeCompression: "by compressing time.",
  Unclickable: "by clicking the unclickable.",
  UndocumentedFunctionCall: "by looking beyond the documentation.",
  RealityAlteration: "by altering reality to suit your whims.",
  N00dles: "by harnessing the power of the n00dles.",
  YoureNotMeantToAccessThis: "by accessing the dev menu.",
  TrueRecursion: "by truly recursing.",
  INeedARainbow: "by using the power of the rainbow.",
};

export function ExploitName(exploit: string): string {
  return names[exploit];
}

export function sanitizeExploits(exploits: Exploit[]): Exploit[] {
  exploits = exploits.filter((e: Exploit) => Object.keys(Exploit).includes(e));
  return [...new Set(exploits)];
}
